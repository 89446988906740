<template>
<v-main class="light">
  <v-col justify="center">
    <v-row justify="center">
      <p class="text-center">This project's emphasis is on creativity and getting comfortable with NFTs that <em><b>actually do something</b></em>.
      <br>
      As such, many tracks on this page will be <em><b>completely free</b></em>.</p>
    </v-row>
    <v-row justify="center">
      <h1 class="text-h3 text-md-h2 text-lg-h2 text-xl-h2 pa-8">Coming soon</h1>
    </v-row>
    
    <!-- Snackbar information area-->
    <v-snackbar v-model="snackbar">
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="snackbar = false">
              Close
            </v-btn>
          </template>
      </v-snackbar>
  </v-col>
  </v-main>
</template>
<style>
  #information-div{
    width:80%;
  }
  #center-text{
    width:100%;
    position: relative;
  }
</style>

<script>

import api from '@/api';
import * as sdk from '@loopring-web/loopring-sdk'
import PrivateKeyProvider from '@loopring-web/web3-provider'

export default {
  name: 'NFT',
  props: {
    act: {
      default: [],
      type: [String],
    },
    prov: PrivateKeyProvider,
    chain: sdk.ChainId,
  },
  metamask_cond: 0,
  ethereum_account: 0,

  data() {
    return {
      result: '',
      snackbar: false,
      snackbarMsg: "",
      isLoopringConnected: false,
    };
  },

  methods: {
    sendToConnect() {
      this.$router.push({
            name: 'Connect',
            params: {
                sender: 'NFT',
            }
        });
    },
    checkMetaMask() {
      this.metamask_cond = api.checkMetaMask();
    },
    connectMetaMask() {
      api.connectMetaMask().then((val)=> this.ethereum_account = val);
    },
    createLrc(){
      api.createLrc(this.act[0], this.prov, this.chain).then((val)=> this.isLoopringConnected = val)
    },
    toneStop(){
      api.toneStop()
    },
  },
  created: function(){
    //api.createLrc(this.act[0], this.prov, this.chain)
  },
}

</script>
